// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
// import bootstrap from "bootstrap";
// import Parallax from 'parallax-js'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require ('jquery-parallax.js');
var Masonry = require('masonry-layout');

import mediumZoom from 'medium-zoom';
require('paroller.js');
const datepicker = require('bootstrap-datepicker');
var moment = require('moment');
moment().format();
// import WOW from'wowjs'
// const WOW = require('wowjs');


// import simpleParallax from 'simple-parallax-js';


const bootstrap = window.bootstrap = require('bootstrap');

const stickyHeaderHeight = 150 ;
// var parallax_images = document.getElementsByClassName('parallax');
// new simpleParallax( parallax_images,{
//     overflow: false
//     // scale: 1.05
//     // orientation: 'right'
// });
if(document.querySelector('.grid')){
    var msnry = new Masonry( '.grid', {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
    });
}

/**
 * Responsive tabs
 */
// (function(  ) {
            
   
            
// });

// ;(function(){
// 	$.fn.datepicker.dates['fr'] = {
// 		days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
// 		daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
// 		daysMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
// 		months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "novembre", "décembre"],
// 		monthsShort: ["Janv.", "Févr.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
// 		today: "Aujourd'hui",
// 		monthsTitle: "Mois",
// 		clear: "Effacer",
// 		weekStart: 1,
// 		format: "dd/mm/yyyy"
// 	};
// });

var today = new Date();
var thisYear = today.getFullYear();
var nextYear = today.getFullYear() + 1;


function init_datepicker(datesDisabled, endDate, daysOfWeekDisabled){
    daysOfWeekDisabled = daysOfWeekDisabled || [0] ;
    $.fn.datepicker.dates['fr'] = {
		days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
		daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
		daysMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
		months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "novembre", "décembre"],
		monthsShort: ["Janv.", "Févr.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
		today: "Aujourd'hui",
		monthsTitle: "Mois",
		clear: "Effacer",
		weekStart: 1,
		format: "dd/mm/yyyy"
	};
   
    $('[name="date-choix-1"], [name="date-choix-2"]').datepicker('destroy');
    $('[name="date-choix-1"], [name="date-choix-2"]').datepicker({
        language: 'fr',
        startDate: today,
        setDate: today,
        endDate: endDate,
        daysOfWeekDisabled: daysOfWeekDisabled,
        daysOfWeekHighlighted: "0",
        datesDisabled: datesDisabled
    });

    $('[name="date-choix-1"]').datepicker().on('changeDate', function(e) {
        //console.log(e);
        var _date1 = [e.date.getDate() ,e.date.getMonth() + 1,e.date.getFullYear() ].join('/');
        datesDisabled.push(_date1);
       // $('[name="date-choix-2"]').datepicker('setDate', today);
        //$('[name="date-choix-2"]').datepicker('setStartDate', startDate);
        $('[name="date-choix-2"]').datepicker('setDatesDisabled', datesDisabled);
      
     });
}

$(document).ready(function() {

    function storeTabs($tabs, $destination) {
        // measure width
        $tabs.each(function() {
            var width = $(this).outerWidth(true);
            $(this).find('a').data('width', width);          
        });
        $tabs.prependTo($destination);
		
		$tabs.find('a').unwrap().removeClass('nav-link').addClass('dropdown-item');
		
    }
            
    function makeTabsResponsive($element) {

        var $tabs = $element.find('li');
        var $firstTab = $tabs.first();
        $firstTab.addClass('first-tab');

        var individualTabHeight = $firstTab.outerHeight();
        var tabsHeight = $element.outerHeight();

        if(tabsHeight > individualTabHeight) {
                    
            // get y pos of first tab
            var firstTabPos = $firstTab.offset();

            var thisTabPos;
            $tabs.each(function() {

                var $thisTab = $(this);

                thisTabPos = $thisTab.offset();
               

                if(thisTabPos.top > firstTabPos.top) {

                    var $dropdown = $element.find('.responsivetabs-more');
                            
                    if(!$dropdown.length) {
                        var dropdownMarkup = '<li class="dropdown show responsivetabs-more">'
                        + '<a href="#tabs" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">...</a>'
                        + '<div class="dropdown-menu -dropdown-menu-end">'
                        + '</div></li>';
                        $dropdown = $(dropdownMarkup);
                        $element.append($dropdown);
                                
                    }
                            
                    var $previousTab = $thisTab.prev();
                    var $followingTabs = $thisTab.nextAll().not('.dropdown');

                    var $destination = $('.dropdown-menu', $dropdown);
                            
                    if(!$thisTab.hasClass('dropdown')) {
                        storeTabs($followingTabs, $destination);
                        storeTabs($thisTab, $destination);
                    }
                    if(!$previousTab.hasClass('first-tab')) {
                        storeTabs($previousTab, $destination);
                    }
                            
                    return;

                }

            });

        } else {
            // check if enough space to move a menu item back out of "..."
            // get parent width
            var parentWidth = $element.parent().width();
            var tabSetWidth = 0;
            var xPxAvailable;

            // calculate total width of tab set (can't just use width of ul because it is 100% by default)
            $element.children('li').each(function() {
                tabSetWidth += $(this).outerWidth(true);
            });

            // calculate available horizontal space
            xPxAvailable = parentWidth - tabSetWidth;

            $element.find('.dropdown-menu a').each(function() {
                if($(this).data('width') <= xPxAvailable) {
					
					// fix for bootstrap 4
					$(this).removeClass('dropdown-item').addClass('nav-link');
					
					
                    $(this).insertBefore($element.find('.responsivetabs-more')).wrap('<li class="nav-item"></li>'); 
                    xPxAvailable -= $(this).data('width');
                } else {
                    return false;
                } 
            });

            // if no menu items left, remove "..."
            if(!$element.find('.dropdown-menu a').length) {
                $element.find('.responsivetabs-more').remove();
            }
        }

   
    }
  
            
    $.fn.responsiveTabs = function() {
                
        this.each(function() {
            var tabs = $(this);
            makeTabsResponsive(tabs); 
            $(window).on('resize', function() {
                makeTabsResponsive(tabs); 
            });                    
        });
                
        return this;
                
    };
    

    $('.nav-tabs').responsiveTabs();

     /**
     * Anchor smooth scroll
     */
      $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .on('click', function(event) {
          // On-page links
          if (
              location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
              && 
              location.hostname == this.hostname
          ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                  // Only prevent default if animation is actually gonna happen
                  event.preventDefault();
                  document.documentElement.scrollTo({
                      top: target.offset().top - stickyHeaderHeight,
                      behavior: "smooth"
                  })
              }
          }
      });

    /**
     * Désactive phlébotonique
     */
    var $select =  $('.wpcf7').find('[name="select-sejour-non-conventionnee"]');
    $select.find('option[value^="Cure Phlébotonique"]').prop('disabled',true);

    /**
     * Crée l'array de dates de fermeture
     */
    var getDaysArray = function(s,e) {
        for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){
             a.push(new Date(d).toLocaleDateString("fr"));
        }
        return a;
    };
    var startDate = new Date(2022, 10, 21) ;
    var _startDate;
    var endDate =  new Date('2024-11-18'); // 18 novembre 2024
    var _endDate;
    var datesDisabled = getDaysArray(new Date("2024-01-01"),new Date("2024-01-02")); // faker pour ne pas avoir une variable vide  ;
    var _datesDisabled = [];
    var _datesDisabled18 = [];
    var _datesDisabledShort = [];
   
    var daysOfWeekDisabled = [] ;
    

    $(document).on('change', '[name="reservationcure"]', function() {
        _datesDisabled = []; // renit on each change
        daysOfWeekDisabled = [0] ;

        if(thermesrochefort.reservation_dates.disabled_dates_global.length > 0){
            thermesrochefort.reservation_dates.disabled_dates_global.forEach(range => {
                let _range = getDaysArray(new Date(range.start),new Date(range.end));
                _datesDisabled.push(_range);
            });
        }
        else{
            _datesDisabled.push(datesDisabled);
        }
        
        let _val = $(this).val() ;
        if( _val == 'Cure Bien-être et prévention santé de 2 à 5 jours (non prise en charge)'){
            //  _datesDisabled = getDaysArray(new Date("2023-03-02"),new Date("2023-04-30"));
            if(thermesrochefort.reservation_dates.disabled_dates_short.length > 0){
                thermesrochefort.reservation_dates.disabled_dates_short.forEach(range => {
                    let _range = getDaysArray(new Date(range.start),new Date(range.end));
                    _datesDisabled.push(_range);
                });
            }
            // if(_datesDisabledShort.length > 0){
            //     _datesDisabled = datesDisabled.concat(_datesDisabledShort);
            // }
           
            _endDate = null !== thermesrochefort.reservation_dates.end_date_short ? thermesrochefort.reservation_dates.end_date_short : endDate ;
            daysOfWeekDisabled.push(1);
        }
        else{
            // _datesDisabled = datesDisabled.concat(datesDisabled3);
            if(thermesrochefort.reservation_dates.disabled_dates_18.length > 0) {
                thermesrochefort.reservation_dates.disabled_dates_18.forEach(range => {
                    let _range = getDaysArray(new Date(range.start),new Date(range.end));
                    _datesDisabled.push(_range);
                });
            }
            // if(_datesDisabled18.length > 0){
            //     _datesDisabled = datesDisabled.concat(_datesDisabled18);
            // }

            _endDate = null !== thermesrochefort.reservation_dates.end_date_18 ? thermesrochefort.reservation_dates.end_date_18 : endDate ;
            daysOfWeekDisabled = [0] ;
        }

        let __datesDisabled = Array.prototype.concat(..._datesDisabled);

        init_datepicker(__datesDisabled, _endDate, daysOfWeekDisabled);
        // console.log(__datesDisabled);
        // console.log(_endDate);
       
    });


    $(window).on('load', function(){
        $('[value="Cure Conventionnée 18 jours"]').trigger('change');
    });

    // var invalid_tip = '<span class="wpcf7-not-valid-tip" aria-hidden="true">Ce champ est obligatoire.</span>';
    // $(document).on('click', '.wpcf7cf_next', function(){
    //     var step = $(this).prev('.wpcf7cf_steps').find('.wpcf7cf_step:visible') ;
    //     console.log(step);
    //     step.find('.wpcf7-validates-as-required').each(function(){
            
    //         console.log($(this));
    //         if($(this).val() == ''){
    //             $(this).after(invalid_tip);
    //             return false ;
    //         }
    //         else{
    //             $(this).next('.wpcf7-not-valid-tip').remove();
    //             return true ;
    //         }
    //     });
    // });

    /** Gestion accompagnant
     * Si accompagnant, redirect vers ?accompagnant=prenom+nom
     */
     document.addEventListener( 'wpcf7mailsent', function( e ) {
        $('.wpcf7-response-output').removeClass('d-none');
        //  console.log(e);
         if(e.detail.contactFormId === 101){
            var has_accompagnant = false ;
            var accompagne_nom = "";
            var accompagne_prenom = "";
            var accompagnant_nom = "";
            var accompagnant_prenom = "";
            var inputs = e.detail.inputs;
            for ( var i = 0; i < inputs.length; i++ ) {
                if( 'select-accompagnant' == inputs[i].name && 'Oui' === inputs[i].value) {
                    has_accompagnant = true ;
                }
                if( 'text-nom' == inputs[i].name) {
                    accompagne_nom = inputs[i].value ;
                }
                if( 'text-prenom' == inputs[i].name) {
                    accompagne_prenom = inputs[i].value ;
                }
                if( 'accompagnant-nom' == inputs[i].name) {
                    accompagnant_nom = inputs[i].value ;
                }
                if( 'accompagnant-prenom' == inputs[i].name) {
                    accompagnant_prenom = inputs[i].value ;
                }
               
            }

            // Si a un accompagnant, alors le 2eme form est prérempli + messge
            if(has_accompagnant){
                $('#modalites-reservation').addClass('d-none');
                $('[name="is_accompagnant_of"]').val(accompagne_nom +' '+accompagne_prenom) ;
                $('[name="text-nom"]').val(accompagnant_nom) ;
                // console.log($('[name="text-nom"]'));
                // console.log(accompagnant_nom);
                $('[name="text-prenom"]').val(accompagnant_prenom) ;
                var accompagnant_message = `<div class="alert alert-success" id="accompagnant-message">Veuillez maintenant effectuer la demande de réservation pour votre accompagnant <strong>${accompagnant_prenom} ${accompagnant_nom}</strong></div>`;
                $('[name="is_accompagnant_of"]').after(accompagnant_message);
                $('form.sent').find('.wpcf7-response-output').addClass('d-none');
            }
            else{
                $('#modalites-reservation').removeClass('d-none');
                $('#accompagnant-message').remove();
                $('form.sent').find('.wpcf7-response-output').removeClass('d-none');
            }

           
         }
       // location = 'http://example.com/';
      }, false );

    //Clone burger icon to overlay menu
    $('.navbar-fixed-opened').on('show.bs.collapse', function () {
        $('.navbar-closer').remove();
        $('.navbar-fixed-opened').prepend(`<button class="navbar-toggler navbar-closer" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMain" aria-controls="navbarMain" aria-expanded="true" aria-label="Fermer le menu">
        <i class="fa fa-lg fa-times"></i></button>`);
    }) ;

    // Close menu when Escape
    $(document).on('keydown', function(e) {
        if (e.key == "Escape") {
            $('#navbarMain').collapse('hide');
        }
    });

    //Wow
    if('function' === typeof WOW){
        new WOW().init();
    }
    

    // Sticky menu
    if (window.matchMedia("(min-width: 768px)").matches && !$('body').hasClass('page-id-102')) {
        window.addEventListener('scroll', function() {
            if (window.scrollY > 160) {
            $('.stickable')
                .addClass('fixed-top')
                // .addClass('bg-dark')
                // .removeClass('bg-transparent');
            let navbar_height = document.querySelector('.navbar').offsetHeight;
            document.body.style.paddingTop = navbar_height + 'px';
            } else {
            $('.stickable')
                .removeClass('fixed-top')
                // .removeClass('bg-dark')
                // .addClass('bg-transparent');
            document.body.style.paddingTop = '0';
            } 
        });
    }

    // $('.dropdown-toggle').dropdown();
    mediumZoom('[data-zoomable]');
    $("[data-paroller-factor]").paroller(); 

    // jarallaxVideo();
    jarallax(document.querySelectorAll('.jarallax'), {
        speed: 0.2
    });

    // Menu
    $(".dropdown").hover(function(){
        var dropdownMenu = $(this).children(".dropdown-menu");
        if(dropdownMenu.is(":visible")){
            dropdownMenu.parent().toggleClass("open");
        }
    });


    $(document).on('click', '.navbar .dropdown-toggle', function(){
        if (window.matchMedia("(min-width: 992px)").matches) {
            window.location.href= $(this).attr('href');
        }
    });
   



    // Counters
    $('.count').each(function () {
        $(this).css('opacity', 1);
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });


    //Scroll back to top
		
    var progressPath = document.querySelector('.progress-wrap path');
    var pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
    progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';		
    var updateProgress = function () {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = pathLength - (scroll * pathLength / height);
        progressPath.style.strokeDashoffset = progress;
    }
    updateProgress();
    $(window).scroll(updateProgress);	
    var offset = 150;
    // var duration = 550;
    jQuery(window).on('scroll', function() {
        if (jQuery(this).scrollTop() > offset) {
            jQuery('.progress-wrap').addClass('active-progress');
        } else {
            jQuery('.progress-wrap').removeClass('active-progress');
        }
    });				
    jQuery('.progress-wrap').on('click', function(event) {
        event.preventDefault();
        document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        return false;
    });

    /**
     * Map dealers
     */
    if($('#contact_map').length > 0){
    
        $.post(ajaxurl, {'action':'geocode_contact_address'}, function(response){
            if(response.lat_lng != ""){
                var contactmap = L.map('contact_map').setView([response.lat_lng.lat, response.lat_lng.lng], 15);

                L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmxpc3RlZmxpc3RlIiwiYSI6ImNpeGt2bnliczAwMjIycW4wMmkwMzI3c2UifQ.fYceJb6F_mtjN3fmXz0wMQ', {
                    maxZoom: 18,
                    // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                    //     'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(contactmap);
           
                var marker = L.marker([response.lat_lng.lat, response.lat_lng.lng], {title:response.name+'<br>'+response.address}).addTo(contactmap);
                marker.bindPopup(response.name+'<br>'+response.address).openPopup();
                    
            }
        
        });

       

        // $('.dealers-list > li').on('click', function(){
        //     var id = $(this).data('id') ;
        //     var _marker = dealersmap.getMarkerById(id);
        //     _marker.fire('click');
        // });
    }


    if($('#dealers').length > 0){
        // Get marker by ID
        L.Map.include({
            getMarkerById: function (id) {
                var marker = null;
                this.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === id) {
                            marker = layer;
                        }
                    }
                });
                return marker;
            }
        });


        var dealersmap = L.map('dealers').setView([44.8947086,-0.2065675], 2);

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmxpc3RlZmxpc3RlIiwiYSI6ImNpeGt2bnliczAwMjIycW4wMmkwMzI3c2UifQ.fYceJb6F_mtjN3fmXz0wMQ', {
            maxZoom: 18,
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(dealersmap);


        var markers = [] ;

        $.get('/wp-json/wp/v2/location', function(response){
            if(response.length > 0){
                for (let i = 0; i < response.length; i++) {
                    const element = response[i];
                    if(element.acf.coords.lat != '' && element.acf.coords.lng != ''){
                        var marker = L.marker([element.acf.coords.lat, element.acf.coords.lng], {title:element.title.rendered,id:element.id}).addTo(dealersmap);
                        marker.bindPopup(element.title.rendered).openPopup();
                        markers.push(marker);
                        marker.on('click', onClick);
                    }
                    
                }
                var group = new L.featureGroup(markers);
                dealersmap.fitBounds(group.getBounds());
            }
        
        });

        function onClick(e) {
            var id = e.target.options.id ;
            $('.dealers-list').find('li').removeClass('list-group-item-primary');
            $('.dealers-list').find('[data-id="'+id+'"]').addClass('list-group-item-primary');
            $('.dealers-list').find('[data-id="'+id+'"]').parents('.accordion-item').find('.accordion-button.collapsed').trigger('click');
        }

        $('.dealers-list > li').on('click', function(){
            var id = $(this).data('id') ;
            var _marker = dealersmap.getMarkerById(id);
            _marker.fire('click');
        });
    }


    /**
     * Form réservation
     * 1. Disable la 2eme orientation en fonction de la 1ere
     */
    $(document).on('change', '[name="select-traitement-1"]', function(){
        $('[name="select-traitement-2"]').find('option').prop('disabled', false);
        var selected_value = $(this).find('option:selected').val();
        var select_2_sibling_option =  $('[name="select-traitement-2"]').find('option[value="'+selected_value+'"]') ;
        select_2_sibling_option.prop('disabled', true);
        select_2_sibling_option.prop('selected', false);
    });


    $(document).on('change', '[name="select-horaire-choix-1"]', function(){
        $('[name="select-horaire-choix-2"]').find('option').prop('disabled', false);
        $('[name="select-horaire-choix-3"]').find('option').prop('disabled', false);
        var selected_value = $(this).find('option:selected').val();
        var select_2_sibling_option =  $('[name="select-horaire-choix-2"]').find('option[value="'+selected_value+'"]') ;
        var select_3_sibling_option =  $('[name="select-horaire-choix-3"]').find('option[value="'+selected_value+'"]') ;
        select_2_sibling_option.prop('disabled', true);
        select_2_sibling_option.prop('selected', false);
        select_3_sibling_option.prop('disabled', true);
        select_3_sibling_option.prop('selected', false);
    });

    $(document).on('change', '[name="select-horaire-choix-2"]', function(){
        //$('[name="select-horaire-choix-3"]').find('option').prop('disabled', false);
        var selected_value = $(this).find('option:selected').val();
        var select_3_sibling_option =  $('[name="select-horaire-choix-3"]').find('option[value="'+selected_value+'"]') ;
        select_3_sibling_option.prop('disabled', true);
        select_3_sibling_option.prop('selected', false);
    });



    
   
});





